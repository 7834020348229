exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-neuheiten-tsx": () => import("./../../../src/pages/neuheiten.tsx" /* webpackChunkName: "component---src-pages-neuheiten-tsx" */),
  "component---src-pages-rug-categories-url-tsx": () => import("./../../../src/pages/{RugCategories.url}.tsx" /* webpackChunkName: "component---src-pages-rug-categories-url-tsx" */),
  "component---src-pages-rug-help-contact-url-tsx": () => import("./../../../src/pages/{RugHelpContact.url}.tsx" /* webpackChunkName: "component---src-pages-rug-help-contact-url-tsx" */),
  "component---src-pages-rug-start-url-tsx": () => import("./../../../src/pages/{RugStart.url}.tsx" /* webpackChunkName: "component---src-pages-rug-start-url-tsx" */),
  "component---src-templates-category-seo-tsx": () => import("./../../../src/templates/category-seo.tsx" /* webpackChunkName: "component---src-templates-category-seo-tsx" */),
  "component---src-templates-location-graphql-tsx": () => import("./../../../src/templates/location-graphql.tsx" /* webpackChunkName: "component---src-templates-location-graphql-tsx" */)
}

